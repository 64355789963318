<template>
    <div class="qr">
        <div class="company-icon">
            <!-- <img class="left" :src="companyIcon['holowits']" alt=""> -->
            <img class="left" src="@/assets/image/registration/163-80.svg" alt="">
            <div class="line"></div>
            <img class="right" :src="companyIcon[companyName]" alt="">
        </div>
        <div class="title first">
            {{ international[language].title1 }}
        </div>
        <div class="t-input">
            <input class="el-input__inner" v-model="form.firstName"/>
            <div :class="['t-label', form.firstName != ''? 'focus': null]">{{international[language].firstName}}</div>
        </div>
        <div class="t-input">
            <input class="el-input__inner" v-model="form.lastName"/>
            <div :class="['t-label', form.lastName != ''? 'focus': null]">{{international[language].lastName}}</div>
        </div>
        <div class="t-input">
            <input class="el-input__inner" v-model="form.email"/>
            <div :class="['t-label', form.email != ''? 'focus': null]">{{international[language].email}}</div>
        </div>
        <div class="t-input">
            <input class="el-input__inner" v-model="form.telephone"/>
            <div :class="['t-label', form.telephone != ''? 'focus': null]">{{international[language].telephone}}</div>
        </div>
        <div class="t-input">
            <el-select v-model="form.jobTitle" filterable :placeholder="international[language].jobTitle" style="width: 100%;" @focus="activity1 = true" @blur="activity1 = false">
                <el-option :label="international[language]['CEO']" value="CEO"></el-option>
                <el-option :label="international[language]['CIO/IT Manager']" value="CIO/IT Manager"></el-option>
                <el-option :label="international[language]['Marketing Director/Manager']" value="Marketing Director/Manager"></el-option>
                <el-option :label="international[language]['Sales Director/Manager']" value="Sales Director/Manager"></el-option>
                <el-option :label="international[language]['Technical Director/Manager']" value="Technical Director/Manager"></el-option>
                <el-option :label="international[language]['Engineering/Technical Staff']" value="Engineering/Technical Staff"></el-option>
                <el-option :label="international[language]['Other']" value="Other"></el-option>
            </el-select>
            <div :class="['t-label', form.jobTitle != ''? 'focus': null, activity1 ? 'focus2': null]">{{international[language].jobTitle}}</div>
        </div>
        <div class="title">
            {{ international[language].title2 }}
        </div>
        <div class="t-input">
            <input class="el-input__inner" v-model="form.companyName"/>
            <div :class="['t-label', form.companyName != ''? 'focus': null]">{{international[language].companyName}}</div>
        </div>
        <div class="t-input">
            <el-select v-model="form.businessType" filterable :placeholder="international[language].businessType" style="width: 100%;" @focus="activity2 = true" @blur="activity2 = false">
                <el-option :label="international[language]['reseller']" value="Reseller"></el-option>
                <el-option :label="international[language]['System Integrator']" value="System Integrator"></el-option>
                <el-option :label="international[language]['Installer']" value="Installer"></el-option>
                <el-option :label="international[language]['Independent Software/Algorithm Vendor']" value="Independent Software/Algorithm Vendor"></el-option>
                <el-option :label="international[language]['Consultant']" value="Consultant"></el-option>
                <el-option :label="international[language]['Marketing Partner']" value="Marketing Partner"></el-option>
                <el-option :label="international[language]['OEM']" value="OEM"></el-option>
                <el-option :label="international[language]['End User']" value="End User"></el-option>
                <el-option :label="international[language]['Security Services Company']" value="Security Services Company"></el-option>
                <el-option :label="international[language]['Alarm Receiving Center']" value="Alarm Receiving Center"></el-option>
                <el-option :label="international[language]['General Contractor']" value="General Contractor"></el-option>
                <el-option :label="international[language]['Other']" value="Other"></el-option>
            </el-select>
            <div :class="['t-label', form.businessType != ''? 'focus': null, activity2 ? 'focus2': null]">{{international[language].businessType}}</div>
        </div>
        <div class="t-input">
            <el-select v-model="form.industry" filterable :placeholder="international[language].industry" style="width: 100%;" @focus="activity3 = true" @blur="activity3 = false">
                <el-option :label="international[language]['Banking and Finance']" value="Banking and Finance"></el-option>
                <el-option :label="international[language]['Entertainment and Gaming']" value="Entertainment and Gaming"></el-option>
                <el-option :label="international[language]['Commercial']" value="Commercial"></el-option>
                <el-option :label="international[language]['Data Centers']" value="Data Centers"></el-option>
                <el-option :label="international[language]['Education']" value="Education"></el-option>
                <el-option :label="international[language]['Government Facilities']" value="Government Facilities"></el-option>
                <el-option :label="international[language]['Healthcare']" value="Healthcare"></el-option>
                <el-option :label="international[language]['Logistics']" value="Logistics"></el-option>
                <el-option :label="international[language]['Manufacturing and Industrial']" value="Manufacturing and Industrial"></el-option>
                <el-option :label="international[language]['Public Transport']" value="Public Transport"></el-option>
                <el-option :label="international[language]['Residential']" value="Residential"></el-option>
                <el-option :label="international[language]['Retail']" value="Retail"></el-option>
                <el-option :label="international[language]['Smart City']" value="Smart City"></el-option>
                <el-option :label="international[language]['Sport and Leisure']" value="Sport and Leisure"></el-option>
                <el-option :label="international[language]['Traffic Management']" value="Traffic Management"></el-option>
                <el-option :label="international[language]['Remote Monitoring Service(RMS)']" value="Remote Monitoring Service(RMS)"></el-option>
                <el-option :label="international[language]['Utilities, Energy and Mining']" value="Utilities, Energy and Mining"></el-option>
                <el-option :label="international[language]['Other']" value="Other"></el-option>
            </el-select>
            <div :class="['t-label', form.industry != ''? 'focus': null, activity3 ? 'focus2': null]">{{international[language].industry}}</div>
        </div>
        <div class="t-input">
            <input class="el-input__inner" v-model="form.city"/>
            <div :class="['t-label', form.city != ''? 'focus': null]">{{international[language].city}}</div>
        </div>
        <div class="title">
            {{ international[language].title3 }}
        </div>
        <div class="t-input">
            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10}" v-model="form.difficulty"></el-input>
        </div>
        <div class="t-input">
            <input type="checkbox" v-model="form.authorization" style="margin-right: 10px;"><i style="color: red;">*</i> {{ international[language].tips }}
            <div v-show="!form.authorization" class="error-info">{{ international[language]['This field is required.'] }}</div>
        </div>
        <div class="t-input" style="display: flex; justify-content: center;">
            <el-button round style="background-color: #c7000b; color: #ffffff; width: 160px;" @click="add">{{ international[language].submit }}</el-button>
        </div>
    </div>
</template>

<script>
import {submit} from '@/api/registration'
 export default {
    name: '',
    data() {
        return {
            companyName: '',
            country: '',
            language: 'en',
            activity1: false,
            activity2: false,
            activity3: false,
            form: {
                firstName: '',
                lastName: '',
                email: '',
                telephone: '',
                jobTitle: '',
                companyName: '',
                businessType: '',
                industry: '',
                city: '',
                difficulty: '',
                authorization: false,
                country: ''
            },
            international: {
                en: {
                    title1: 'How can we reach you?',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    email: 'Email',
                    telephone: 'Telephone',
                    jobTitle: 'Job Title',
                    CEO: 'CEO',
                    'CIO/IT Manager': 'CIO/IT Manager',
                    'Marketing Director/Manager': 'Marketing Director/Manager',
                    'Sales Director/Manager': 'Sales Director/Manager',
                    'Technical Director/Manager': 'Technical Director/Manager',
                    'Engineering/Technical Staff': 'Engineering/Technical Staff',
                    'Other': 'Other',
                    title2: 'Company Details',
                    companyName: 'Company Name',
                    businessType: 'Business Type',
                    reseller: 'Reseller',
                    industry: 'Industry',
                    city: 'City',
                    title3: 'What can we help you for future cooperation?',
                    tips: 'For future cooperation and to provide you with better services, HOLOWITS and its local distributors may retain your information.',
                    submit: 'Submit',
                    'This field is required.': 'This field is required.',
                    'Banking and Finance': 'Banking and Finance',
                    'Entertainment and Gaming': 'Entertainment and Gaming',
                    'Commercial': 'Commercial',
                    'Data Centers': 'Data Centers',
                    'Education': 'Education',
                    'Government Facilities': 'Government Facilities',
                    'Healthcare': 'Healthcare',
                    'Logistics': 'Logistics',
                    'Manufacturing and Industrial': 'Manufacturing and Industrial',
                    'Public Transport': 'Public Transport',
                    'Residential': 'Residential',
                    'Retail': 'Retail',
                    'Smart City': 'Smart City',
                    'Sport and Leisure': 'Sport and Leisure',
                    'Traffic Management': 'Traffic Management',
                    'Remote Monitoring Service(RMS)': 'Remote Monitoring Service(RMS)',
                    'Utilities, Energy and Mining': 'Utilities, Energy and Mining',
                    'System Integrator': 'System Integrator',
                    'Installer': 'Installer',
                    'Independent Software/Algorithm Vendor': 'Independent Software/Algorithm Vendor',
                    'Consultant': 'Consultant',
                    'Marketing Partner': 'Marketing Partner',
                    'OEM': 'OEM',
                    'End User': 'End User',
                    'Security Services Company': 'Security Services Company',
                    'Alarm Receiving Center': 'Alarm Receiving Center',
                    'General Contractor': 'General Contractor'
                },
                thai: {
                    title1: 'เราจะติดต่อคุณอย่างไรได้บ้าง？',
                    firstName: 'ชื่อจริง',
                    lastName: 'นามสกุล',
                    email: 'อีเมล',
                    telephone: 'เบอร์โทรศัพท์',
                    jobTitle: 'ตำแหน่งงาน',
                    CEO: 'ผู้บริหารสูงสุด',
                    'CIO/IT Manager': 'ผู้บริหารเทคโนโลยีสารสนเทศ/ผู้จัดการฝ่ายไอที',
                    'Marketing Director/Manager': 'ผู้อำนวยการฝ่ายการตลาด/ผู้จัดการ',
                    'Sales Director/Manager': 'ผู้อำนวยการฝ่ายขาย/ผู้จัดการ',
                    'Technical Director/Manager': 'ผู้อำนวยการฝ่ายเทคนิค/ผู้จัดการ',
                    'Engineering/Technical Staff': 'วิศวกร/ช่างเทคนิค',
                    'Other': 'อื่นๆ',
                    title2: 'รายละเอียดบริษัท',
                    companyName: 'ชื่อบริษัท',
                    businessType: 'ประเภทธุรกิจ',
                    reseller: 'ตัวแทนจำหน่าย',
                    industry: 'อุตสาหกรรม',
                    city: 'เมือง',
                    title3: 'เราสามารถช่วยอะไรคุณได้บ้างสำหรับความร่วมมือในอนาคต?',
                    tips: 'สำหรับความร่วมมือในอนาคตและเพื่อให้บริการที่ดีขึ้นแก่คุณ, ทาง HOLOWITS และตัวแทนจำหน่ายในท้องถิ่นอาจเก็บข้อมูลของคุณไว้',
                    submit: 'ส่ง',
                    'This field is required.': 'ช่องนี้จำเป็น',
                    'Banking and Finance': 'การธนาคารและการเงิน',
                    'Entertainment and Gaming': 'ความบันเทิงและการเล่นเกม',
                    'Commercial': 'ทางการค้า',
                    'Data Centers': 'ศูนย์ข้อมูล',
                    'Education': 'การศึกษา',
                    'Government Facilities': 'สถานที่ราชการ',
                    'Healthcare': 'การดูแลสุขภาพ',
                    'Logistics': 'โลจิสติกส์',
                    'Manufacturing and Industrial': 'การผลิตและอุตสาหกรรม',
                    'Public Transport': 'การขนส่งสาธารณะ',
                    'Residential': 'ที่อยู่อาศัย',
                    'Retail': 'ขายปลีก',
                    'Smart City': 'เมืองอัจฉริยะ',
                    'Sport and Leisure': 'กีฬาและสันทนาการ',
                    'Traffic Management': 'การจัดการจราจร',
                    'Remote Monitoring Service(RMS)': 'การบริการติดตามตรวจวัดข้อมูลระยะไกล',
                    'Utilities, Energy and Mining': 'ด้านพลังงาน,สาธารณูปโภคและเหมืองแร่',
                    'System Integrator': 'ผู้รับเหมาระบบ',
                    'Independent Software/Algorithm Vendor': 'ผู้จำหน่ายซอฟต์แวร์อิสระ/อัลกอริทึม',
                    'Consultant': 'ที่ปรึกษา',
                    'Marketing Partner': 'พันธมิตรทางการตลาด',
                    'OEM': 'OEM',
                    'End User': 'ผู้ใช้งานหรือผู้บริโภค',
                    'Security Services Company': 'บริษัทให้บริการรักษาความปลอดภัย',
                    'Alarm Receiving Center': 'ศูนย์รับสัญญาณเตือนภัย',
                    'General Contractor': 'ผู้รับเหมาทั่วไป'
                },
                es: {
                    title1: '¿Cómo podemos llegar hasta usted?',
                    firstName: 'Nombre',
                    lastName: 'Apellidos',
                    email: 'Correo electrónico',
                    telephone: 'Teléfono',
                    jobTitle: 'Puesto',
                    CEO: 'Director Ejecutivo',
                    'CIO/IT Manager': 'Director/Gerente de Tecnologías de la Información',
                    'Marketing Director/Manager': 'Director/Gerente de Mercadotecnia',
                    'Sales Director/Manager': 'Director/Gerente de Ventas',
                    'Technical Director/Manager': 'Director/Gerente Técnico',
                    'Engineering/Technical Staff': 'Ingeniería/Personal Técnico',
                    'Other': 'Otro',
                    title2: 'Información de la compañía',
                    companyName: 'Nombre de la empresa',
                    businessType: 'Tipo de Negocio',
                    reseller: 'Distribuidor',
                    industry: 'Industria',
                    city: 'Ciudad',
                    title3: '¿Como podemos ayudarle para una futura cooperación?',
                    tips: 'Para una cooperación futura y para proporcionarle mejores servicios, HOLOWITS y sus distribuidores locales pueden retener su información',
                    submit: 'Envíar',
                    'This field is required.': 'Este campo es requerido.',
                    'Banking and Finance': 'Banca y Financiero',
                    'Entertainment and Gaming': 'Entretenimiento y video juegos',
                    'Commercial': 'Comercial',
                    'Data Centers': 'Centros de Datos',
                    'Education': 'Educación',
                    'Government Facilities': 'Gobierno',
                    'Healthcare': 'Salud',
                    'Logistics': 'Logística',
                    'Manufacturing and Industrial': 'Manufactura e Industria',
                    'Public Transport': 'Transporte público',
                    'Residential': 'Residencial',
                    'Retail': 'Minorista',
                    'Smart City': 'Ciudad inteligente',
                    'Sport and Leisure': 'Deporte y Esparcimiento',
                    'Traffic Management': 'Gestión de tráfico',
                    'Remote Monitoring Service(RMS)': 'Servicio de Monitoreo Remoto (RMS)',
                    'Utilities, Energy and Mining': 'Servicios Públicos, Energía y Minería',
                    'System Integrator': 'Integrador',
                    'Installer': 'Instalador',
                    'Independent Software/Algorithm Vendor': 'Proveedor independiente de software/algoritmos',
                    'Consultant': 'Consultor',
                    'Marketing Partner': 'Socio de Mercadotecnia',
                    'OEM': 'OEM',
                    'End User': 'Usuario final',
                    'Security Services Company': 'Empresa de servicios de seguridad',
                    'Alarm Receiving Center': 'Centro de recepción de alarmas',
                    'General Contractor': 'Contratista General'
                }
            },
            companyIcon: {
                holowits: '../../assets/image/registration/155-21.svg',
                // holowits: 'https://resources.holowits.com.sg/company_icon/7fe9ae6a9c0e43d2a65e11d999451121.svg',
                tct: 'https://hwtresources.obs.ap-southeast-3.myhuaweicloud.com/company_icon/TCT%20Group.png',
                aictec: 'https://hwtresources.obs.ap-southeast-3.myhuaweicloud.com/company_icon/AICTEC.png',
                boa: 'https://hwtresources.obs.ap-southeast-3.myhuaweicloud.com/company_icon/Bangkok%20OA%20Coms%20Co.%2CLtd..png',
                "fares": "https://resources.holowits.com.sg/company_icon/94f6a21381674033aca5745afdf49f3e.png",
                "d-ron": "https://resources.holowits.com.sg/company_icon/185e651952c846dc964697c63140721c.png",
                "3corp": "https://resources.holowits.com.sg/company_icon/921013f965294a8b9724baa70de47500.png",
                "magnus": "https://resources.holowits.com.sg/company_icon/384d9af740a34ee285502a4d65c706cf.png",
                "computec": "https://resources.holowits.com.sg/company_icon/2cb44de8cec446dab56f88b61273919d.png",
                "munnings": "https://resources.holowits.com.sg/company_icon/75e02a5eb8a9424082d1b108a7d1c273.png",
                "vstecs": "https://resources.holowits.com.sg/company_icon/9652debf6f9d478bb0a9a54b87b8c16c.png",
                "vst": "https://resources.holowits.com.sg/company_icon/b86667990e204e0188e137fc42103974.png",
                "hiperdist": "https://resources.holowits.com.sg/company_icon/f4fcc646050d41a797d1a062b7f37780.png",
                "adept": "https://resources.holowits.com.sg/company_icon/a994515099d6437cb2ea24222e44c89b.png",
                "awan": "https://resources.holowits.com.sg/company_icon/b5dd17e54f5e4c0097e46ebddab2859a.png",
                "biges": "https://resources.holowits.com.sg/company_icon/2848be3a5fd142c59aef7eff8b71a2a1.png",
                "bydemes": "https://resources.holowits.com.sg/company_icon/8981adf626154ee386631053b6722562.png",
                "ccet": "https://resources.holowits.com.sg/company_icon/c812ee8ed1b94bae9d1b14e77d8a28d3.png",
                "connectoway": "https://resources.holowits.com.sg/company_icon/0eee79a2e1c04fc39d702106a2eb5b5e.png",
                "digits": "https://resources.holowits.com.sg/company_icon/3713ae6f844e42988ab8a4ce67aa8ac8.png",
                "ezzy": "https://resources.holowits.com.sg/company_icon/12c1da59aabf4e1bb670ec5c9e068bfc.png",
                "iplus": "https://resources.holowits.com.sg/company_icon/8322c83d4a3c43dcaa7ae5781d72a0aa.png",
                "integra": "https://resources.holowits.com.sg/company_icon/6a62fe99fcff4af4ba7b3e142f509732.png",
                "oktabit": "https://resources.holowits.com.sg/company_icon/9b9db79e816249a5a1745c5f5273b700.png",
                "pinnacle": "https://resources.holowits.com.sg/company_icon/2c4c62d768f84716b867fb63775003b5.png",
                "sti": "https://resources.holowits.com.sg/company_icon/0ee0056bb7f04bbabe1751ac7db3667f.png",
                "securitymarket": "https://resources.holowits.com.sg/company_icon/792855c63ced48a785a1f370a59e0d91.png",
                "sertseg": "https://resources.holowits.com.sg/company_icon/48d2d42bc05c426ba3b115dfa629d447.png",
                "smart": "https://resources.holowits.com.sg/company_icon/8b30d9bbcbeb4bfaa65829dabad6a643.png",
                "synnex": "https://resources.holowits.com.sg/company_icon/04e6c529c0114316b53b830771fe73df.png",
                "teletech": "https://resources.holowits.com.sg/company_icon/57884d2301684dd498fbae44b412675f.png",
                "ution": "https://resources.holowits.com.sg/company_icon/3c97a6b72bbb4deb8e4bd88c140fa22b.png",
                "videosec": "https://resources.holowits.com.sg/company_icon/094708b67e54489d8d236a40d90cbdf7.png",
                "wsi": "https://resources.holowits.com.sg/company_icon/bc8f713541614d2f9699b9a612f99953.png",
                "cbc": "https://resources.holowits.com.sg/company_icon/ece6c294560344dea1841d70848ea2ec.png",
                "spu": "https://resources.holowits.com.sg/company_icon/1604e13637b74dae9b2e396692dce144.png",
                "mustek": "https://resources.holowits.com.sg/company_icon/d4dce480ee014f6fb9aff469ccc447fa.png",
                "atacom": "https://resources.holowits.com.sg/company_icon/fafaa8b1440b456796e7d287f60d80cd.png"
            }
        }
    },
    methods: {
        fChkMail(emailAddress){ 
            var reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$");
            var bChk = reg.test(emailAddress);
            return bChk;
        },
        async add() {
           if (!this.fChkMail(this.form.email)) {
            this.$message.error('email format error');
            return false;
           }
           if (!this.form.authorization) {
            return false;
           }
           let keys = Object.keys(this.form)
           for (let key of keys) {
            if (this.form[key] === '' || this.form[key] === false) {
                this.$message.error('This field is required.')
                return false;
            }
           }
           const {data: {data, isSuccess}} = await submit(this.form);
           if (isSuccess) {
            this.$message.success('Success');
           } else {
            this.$message.success('Fail');
           }
        }
    },
    mounted() {
        this.companyName = this.$route.params.companyName
        this.country = this.$route.params.country
        this.form.country = `${this.country}-${this.companyName}`
        // console.log(this.$route)
        if (this.$route.query.language) {
            this.language = this.$route.query.language
        }
    },
    filters: {

    }
 }
</script>

<style lang="scss" scope>
.error-info {
    color: red;
    font-size: 12px;
}
.company-icon {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .left {
        width: 163px;
        height: 80px;
    }
    .line {
        height: 40px;
        width: 2px;
        background-color: #000000;
    }
    .right {
        width: 105px;
        height: 105px;
    }
}
.first {
    margin-top: 20px;
}

.title {
    font-size: 18px;;
    font-weight: 600;
    margin-bottom: 20px;
}
.t-input {
    margin-bottom: 20px;
    position: relative;

    .t-label {
        position: absolute;
        font-size: 14px;
        top: 10px;
        left: 16px;
        background-color: #ffffff;
        color: #C0C4CC;
        transition: all 0.4s;

        &::after {
            content: '*';
            padding-left: 2px;
            color: #c7000b;
        }
    }
    .focus {
        padding: 0 5px;
        top: -12px;
        font-size: 12px;
    }
    .focus2 {
        padding: 0 5px;
        top: -12px;
        left: 12px;
        font-size: 12px;
    }
    .el-input__inner:focus+.t-label {
        padding: 0 5px;
        top: -12px;
        font-size: 12px;
    }
}
@media only screen and (min-width: 992px) {
    .qr {
        max-width: 838px;
        margin: 20px auto;
    }
}
@media only screen and (max-width: 991px) {
    .qr {
        width: 100%;
        margin: 20px auto;
        padding: 15px;
    }
}
</style>